.md body {
  transition: 0.25s;
  --ion-text-color: #ffffff !important;
}

.text-secondary-room {
  color: #505050;
}

ion-action-sheet.text-button-sheet .action-sheet-title {
  color: #505050;
}

ion-action-sheet.text-button-sheet .action-sheet-button {
  color: #ffffff;
}

.text-secondary-size-small {
  font-size: 0.75rem !important;
}

.overflow-text {
  overflow: hidden !important;
  text-overflow: ellipsis !important; 
}

.ion-color-unobike-red {
  --ion-color-base: var(--unobike-color-red);
  --ion-color-base-rgb: var(--unobike-color-red-rgb);
  --ion-color-contrast: var(--unobike-color-red-contrast);
  --ion-color-contrast-rgb: var(--unobike-color-red-contrast-rgb);
  --ion-color-shade: var(--unobike-color-red-shade);
  --ion-color-tint: var(--unobike-color-red-tint);
}

.buttonAccordion::part(native) {
  background-color: #1e1e1e;
  border-bottom: 1px solid var(--ion-color-light);
  color: #ffffff;
}

.buttonAccordion ion-icon {
  color: #ffffff;
}

.icon-login {
  width: 100%;
  height: 50px;
  color: var(--unobike-color-light-grey);
}

.room-stick::part(native) .item-inner {
  background-color: rgba(100, 100, 100, 0.1) !important;
}

.full-height {
  height: 100%;
  overflow-y: auto;
}

.unobike-background {
  background-color: #1e1e1e;
  --ion-background-color: #1e1e1e;
}

.border-col-left {
  border-left: 1px solid var(--ion-color-light);
}

.border-col-right {
  border-right: 1px solid var(--ion-color-light);
}

.shadow-box-bottom {
  box-shadow: 0 5px 4px -4px rgb(20, 20, 20);
  z-index: 1;
}

.shadow-box-top {
  box-shadow: 0 -5px 4px -4px rgb(20, 20, 20);
  z-index: 1;
}

.chat-padding-top {
  padding-top: 10px;
}

ion-textarea.chat-textarea {
  --background: #373737;
  --color: #fff;
  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
}

.text-bubble-customer {
  color: #fff;
  border: 1px solid rgb(100, 100, 100);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}

.text-bubble-user {
  background-color: #373737;
  color: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}

.message-item i {
  font-size: 0.7rem;
  float: inline-end;
  color: rgb(100, 100, 100);
}

.white-space-message {
  white-space: pre-wrap;
}

.message-thumb {
  width: max-content;
  height: max-content;
  max-width: 30vh;
  max-height: 30vh;
}

.message-thumb-max {
  max-height: 30vh;
}

.message-thumb-video {
  max-width: 30vh;
  max-height: 40vh;
}

.back-primary {
  background-color: var(--ion-color-primary);
}

.back-success {
  background-color: var(--ion-color-success);
}

.back-warning {
  background-color: var(--ion-color-warning);
}

.back-danger {
  background-color: var(--ion-color-danger);
}

.call-time {
  font-size: 0.8rem;
}

.chatbox-padding-bottom {
  padding-bottom: 140px;
}

.fix-bottom {
  position: fixed;
  width: 100%;
  bottom: 0px;
  z-index: 1;
}

.color-instagram {
  color: #e4405f;
}

.poppoverEmojis {
  --width: 350px;
  --height: 450px;
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  ion-modal.modal-customer::part(content) {
    width: 80%;
  }

  .fix-bottom {
    width: 75%;
  }

  .chatbox-padding-bottom {
    padding-bottom: 65px;
  }

  .full-height {
    height: 81vh;
  }

  ion-modal.modal-newChat::part(content) {
    width: max-content;
    height: 25vh;
  }
}